<template>
  <div class="aiz-user-panel">
    
      <div class="card">
        <div class="card-header">
            <h5 class="mb-0 h6">{{ $t('xia-zai-nin-de-chan-pin') }}</h5>
        </div>
        <div class="card-body">
          <table class="table mb-0 aiz-table footable ">
              <thead>
                  <tr>
                      <th>{{ $t('chan-pin') }}</th>
                      <th width="20%">{{ $t('xuan-xiang') }}</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in dataList" :key="i">
                    <td><a >{{ item.code }}</a></td>
                    <td>
                      <a class="btn btn-soft-info btn-icon btn-circle btn-sm" :title="$t('xia-zai-0')">
                          <i class="las la-download"></i>
                      </a>
                    </td>
                </tr>
                <tr class="footable-empty" v-if="dataList.length == 0"><td colspan="2">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
              </tbody>
          </table>
          <div class="aiz-pagination" v-show="dataList.length > 0">
            <pageBar :totalPage="56" :page="51"></pageBar>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      dataList: []
    }
  }
}
</script>